import React from "react";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import "./Hero.css";

const HomeComponent = props => (
  <Container textAlign="center" id="home">
    <Header
      as="h1"
      inverted
      style={{
        fontSize: "3em",
        fontWeight: "300",
        marginBottom: 0,
        marginTop: "3em"
      }}
    >
      THE NO.1 <b>INSTAGRAM TOOL</b> FOR <b>FITNESS</b> INFLUENCERS TO
    </Header>
    <div className="flicker-container">
      <Header
        as="h1"
        inverted
        style={{
          fontSize: "3em",
          fontWeight: "300",
          marginBottom: "0.4em",
          marginTop: "0.2em"
        }}
        className="shadow-1"
      >
        Boost Engagement
      </Header>
      <Header
        as="h1"
        inverted
        style={{
          fontSize: "3em",
          fontWeight: "300",
          marginBottom: "0.4em",
          marginTop: "0.2em"
        }}
        className="shadow-2"
      >
        Gain Followers
      </Header>
      <Header
        as="h1"
        inverted
        style={{
          fontSize: "3em",
          fontWeight: "300",
          marginBottom: "0.4em",
          marginTop: "0.2em"
        }}
        className="shadow-3"
      >
        Send DM Campaigns
      </Header>
      <Header
        as="h1"
        inverted
        style={{
          fontSize: "3em",
          fontWeight: "300",
          marginBottom: "0.4em",
          marginTop: "0.2em"
        }}
        className="shadow-4"
      >
        Schedule Content
      </Header>
      <Header
        as="h1"
        inverted
        style={{
          fontSize: "3em",
          fontWeight: "300",
          marginBottom: "0.4em",
          marginTop: "0.2em"
        }}
        className="shadow-5"
      >
        Manage Comments
      </Header>
    </div>
    <Segment basic compact style={{ margin: "auto" }}>
      <Button
        onClick={() => props.handleClick("pricing")}
        size="massive"
        className="colorchange-button"
        color="pink"
      >
        Get Started
      </Button>
    </Segment>
    <Header
      as="h5"
      content=""
      style={{
        fontSize: "1.5em",
        fontWeight: "normal",
        marginTop: "1em",
        marginBottom: "2em",
        paddingTop: "1em",
        paddingBottom: "1em"
      }}
    />
  </Container>
);

export default HomeComponent;
