import React from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  List,
  Segment
} from "semantic-ui-react";

const StrategiesComponent = () => (
  <Segment id="features" color="pink" style={{ padding: "3em 0em" }} vertical>
    <Container fluid>
      <Header as="h2" style={{ fontSize: "3em" }} textAlign="center">
        Proven strategies
      </Header>
      <Container className="strategies-list-container" fluid>
        <Grid stackable columns={4} centered>
          <Grid.Column>
            <List size="big">
              <List.Item>
                <Icon color="violet" name="calendar alternate outline" />
                <List.Content>
                  <List.Header>Schedule Posts</List.Header>
                  <List.Description>
                    Posting consistently is the #1 way to organically increase
                    your audience
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List size="big">
              <List.Item>
                <Icon color="teal" name="slack hash" />
                <List.Content>
                  <List.Header>Target Audience</List.Header>
                  <List.Description>
                    Automatically engage by following specific hashtags,
                    locations, competitors and more
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List size="big">
              <List.Item>
                <Icon color="green" name="mail" />
                <List.Content>
                  <List.Header>
                    Email Marketing <span>NEW!</span>
                  </List.Header>
                  <List.Description>
                    Send exclusive behind the scenes emails for your scheduled
                    posts helping to drive massive engagement!
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </Container>
    </Container>
  </Segment>
);

export default StrategiesComponent;
