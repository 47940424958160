import React from "react";
import { Grid, Header, Icon, Segment } from "semantic-ui-react";

const Benefits = () => (
  <Segment
    id="benefits"
    style={{ padding: "6em 0em 3em" }}
    color="grey"
    vertical
    inverted
  >
    <Grid stackable>
      <Grid.Row centered columns={4}>
        <Grid.Column>
          <Header as="h2" icon textAlign="center">
            <Icon name="users" color="violet" circular />
            <Header.Content>
              Increase Followers and Likes
              <Header.Subheader style={{ padding: "0.4em 0" }}>
                MogulKit uses proven growth and engagement strategies to gain
                massive social media followings.
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h2" icon textAlign="center">
            <Icon name="heart" color="red" circular />
            <Header.Content>
              Increase Engagement
              <Header.Subheader style={{ padding: "0.4em 0" }}>
                With more followers and more reach, you will get more likes and
                comments.
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h2" icon textAlign="center">
            <Icon name="money" color="green" circular />
            <Header.Content>
              Business Opportunity
              <Header.Subheader style={{ padding: "0.4em 0" }}>
                Increasing your influence (Mogul Status) increases your
                opportunity for business and paid partnerships.
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default Benefits;
