import React from "react";
import { Container } from "semantic-ui-react";

import FooterComponent from "./FooterComponent";
import TopbarComponent from "./TopbarComponent";
import ScrollSinkComponent from "./ScrollSinkComponent";

import { analytics } from "./services";

class FrameContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
      activeSection: "home",
      showTip: false
    };
    this.scrollToSection = this.scrollToSection.bind(this);
  }

  componentDidMount() {
    analytics.logEvent("VIEW", { page: "TERMS", version: "0.0.1" });
  }

  scrollToSection(section) {
    const el = document.getElementById(section);
    if (!el) {
      console.log("No Element");
      return;
    }
    const topEl = document.getElementById("topBarComponent");
    const { height } = topEl.getBoundingClientRect();
    const scrollTop = el.offsetTop - el.scrollTop - height;
    this.setState({
      scrollTop,
      activeSection: section
    });
    analytics.logEvent(analytics.EventNames.SELECTED_MENU_ITEM, { section });
  }

  render() {
    return (
      <div>
        <Container
          fluid
          style={{
            backgroundColor: "#ffffff",
            position: "fixed",
            top: "0",
            right: "0",
            left: "0",
            zIndex: "1001",
            paddingTop: "0.75em",
            paddingBottom: "0.75em"
          }}
          id="topBarComponent"
        >
          <Container>
            <TopbarComponent />
          </Container>
        </Container>
        <Container fluid>{this.props.children}</Container>
        <FooterComponent scrollToSection={this.scrollToSection} />
        <ScrollSinkComponent scrollTop={this.state.scrollTop} />
      </div>
    );
  }
}

export default FrameContainer;
