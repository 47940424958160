import firebase from "firebase/app";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCFwCnMisNopnEuxlcYeppF1mOa4nHeYmU",
  authDomain: "mogulkit.firebaseapp.com",
  databaseURL: "https://mogulkit.firebaseio.com",
  projectId: "mogulkit"
};
firebase.initializeApp(config);

const firestore = firebase.firestore();
const settings = { /* your settings... */ timestampsInSnapshots: true };
firestore.settings(settings);

export default firebase;
