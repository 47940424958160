import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import ScrollTop from "./ScrollTop";
import BodyContainer from "./BodyContainer";
import PaymentFormContainer from "./PaymentFormContainer";
import SignupFormContainer from "./SignupFormContainer";
import TermsComponent from "./TermsComponent";
import PrivacyComponent from "./PrivacyComponent";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Route exact path="/" component={BodyContainer} />
          <Route path="/payment" component={PaymentFormContainer} />
          <Route path="/signup" component={SignupFormContainer} />
          <Route path="/terms" component={TermsComponent} />
          <Route path="/privacy" component={PrivacyComponent} />
          <ScrollTop />
        </div>
      </Router>
    );
  }
}

export default App;
