import React from "react";
import { Header, Icon } from "semantic-ui-react";

const WaitlistComponent = () => (
  <Header as="h2" textAlign="center" icon>
    <Icon color="pink" style={{ margin: "auto" }} name="clipboard outline" />
    Signups Full! You have been added to the waitlist.
    <Header.Subheader>
      You card will not be charged until you are off the waitlist and you decide
      to activate your account. Please use the chat widget below if you have any
      questions.
    </Header.Subheader>
  </Header>
);

export default WaitlistComponent;
