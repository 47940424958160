import React from "react";
import { Container, Segment } from "semantic-ui-react";

import SignupFormComponent from "./SignupFormComponent";
import TopbarComponent from "./TopbarComponent";

const SignupFormContainer = props => {
  const { location } = props || {};
  const { hash } = location;
  const planKey = hash.replace("#", "");
  return (
    <div>
      <Container
        fluid
        style={{
          backgroundColor: "#ffffff",
          position: "fixed",
          top: "0",
          right: "0",
          left: "0",
          zIndex: "1001",
          paddingTop: "0.75em",
          paddingBottom: "0.75em"
        }}
        id="topBarComponent"
      >
        <Container>
          <TopbarComponent />
        </Container>
      </Container>
      <Container text style={{ marginTop: "6em" }}>
        <Segment color="pink">
          <SignupFormComponent hash={planKey} />
        </Segment>
      </Container>
    </div>
  );
};

export default SignupFormContainer;
