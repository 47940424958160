import React from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Header, Icon } from 'semantic-ui-react';
import { airtable, analytics, firebase } from './services';

const FormState = {
  isLoading: 'isLoading',
  isSubmitted: 'isSubmitted',
  default: 'default',
};

class SignupFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      formState: FormState.default,
    };
  }
  componentDidMount() {
    analytics.logEvent('VIEW SIGNUP FORM');
  }
  handleChange = (e, v) => {
    const { name, value } = v;
    const state = this.state;
    state[name] = value;
    this.setState(state);
  };
  handleSubmit = async submit => {
    this.setState({
      formState: FormState.isLoading,
    });
    const { name, email, password } = this.state;
    const plan = this.props.hash;
    firebase
      .firestore()
      .collection('signups')
      .doc(email)
      .set({ name, email, password, plan, createdAt: new Date() })
      .then(() => {
        localStorage.setItem('email', email);
        localStorage.setItem('name', name);
        this.setState({ formState: FormState.isSubmitted });
      });

    await airtable('Leads').create([
      {
        fields: {
          Email: email,
          'Created At': new Date(),
          Name: name,
          Plan: plan,
        },
      },
    ]);
    this.setState({ loading: false, submitted: true });

    analytics.logEvent(analytics.EventNames.SUBMITTED_SIGNUP_FORM, { plan });

    window.gtag('event', 'generate_lead', plan);

    window.fbq('track', 'Lead');
  };
  render() {
    if (this.state.formState === FormState.isSubmitted) {
      // if (this.props.hash.toLowerCase() === "popular") {
      //   return <WaitlistComponent />;
      // }
      return <Redirect to={`/payment#${this.props.hash}`} />;
    }
    const { name, email } = this.state;
    return (
      <Form size="big" loading={this.state.formState === FormState.isLoading}>
        <Header textAlign="center" icon>
          <Icon name="chain" color="pink" />
          MogulKit
        </Header>
        <Form.Input
          placeholder="Name"
          name="name"
          value={name}
          onChange={this.handleChange}
          autoComplete="name"
        />
        <Form.Input
          placeholder="Email"
          name="email"
          value={email}
          onChange={this.handleChange}
          autoComplete="email"
        />
        <Form.Input
          placeholder="Password"
          name="password"
          type="password"
          onChange={this.handleChange}
          autoComplete="new-password"
        />
        <Form.Button onClick={this.handleSubmit} size="big" primary fluid>
          Sign Up
        </Form.Button>
      </Form>
    );
  }
}

export default SignupFormComponent;
