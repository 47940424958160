import React from "react";
import { Button, Container, Segment } from "semantic-ui-react";

import HeroComponent from "./HeroComponent";
import BenefitsComponent from "./BenefitsComponent";
import FooterComponent from "./FooterComponent";
import MenuComponent from "./MenuComponent";
import PricingComponent from "./PricingComponent";
import StrategiesComponent from "./StrategiesComponent";
import TopbarComponent from "./TopbarComponent";
import ScrollSinkComponent from "./ScrollSinkComponent";

import { analytics } from "./services";

class BodyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
      activeSection: "home",
      showTip: false
    };
    this.scrollToSection = this.scrollToSection.bind(this);
  }

  componentDidMount() {
    // analytics.logEvent("VIEW", { page: "HOME", version: "0.0.1" });
  }

  scrollToSection(section) {
    const el = document.getElementById(section);
    const topEl = document.getElementById("topBarComponent");
    const { height } = topEl.getBoundingClientRect();
    const scrollTop = el.offsetTop - el.scrollTop - height;
    this.setState({
      scrollTop,
      activeSection: section
    });
    analytics.logEvent(analytics.EventNames.SELECTED_MENU_ITEM, { section });
  }

  render() {
    return (
      <div>
        <Segment inverted className="hero">
          <Container
            fluid
            style={{
              // backgroundColor: "#ffffff",
              // position: "fixed",
              // top: "0",
              // right: "0",
              // left: "0",
              // zIndex: "1001s",
              paddingTop: "0.75em",
              paddingBottom: "0.75em"
            }}
            id="topBarComponent"
          >
            <Container>
              <TopbarComponent>
                <MenuComponent
                  activeSection={this.state.activeSection}
                  handleClick={this.scrollToSection}
                />
              </TopbarComponent>
            </Container>
          </Container>
          <Container fluid>
            <HeroComponent handleClick={this.scrollToSection} />
          </Container>
        </Segment>
        <Container fluid>
          <BenefitsComponent />
        </Container>
        <Container fluid>
          <StrategiesComponent handleClick={this.scrollToSection} />
        </Container>
        <Container fluid>
          <PricingComponent changeViewState={this.changeViewState} />
        </Container>
        <Container style={{ padding: "5em 0 12em" }} fluid>
          <Segment basic style={{ margin: "auto" }} />
        </Container>
        <FooterComponent scrollToSection={this.scrollToSection} />
        <ScrollSinkComponent scrollTop={this.state.scrollTop} />
      </div>
    );
  }
}

export default BodyContainer;
