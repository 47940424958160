import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button, Icon, List, Segment } from 'semantic-ui-react';

import WaitlistComponent from './WaitlistComponent';
import { analytics, firebase } from './services';

const FormState = {
  isLoading: 'isLoading',
  isSubmitted: 'isSubmitted',
  default: 'default',
};

class PaymentFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: FormState.default,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    analytics.logEvent('VIEW PAYMENT FORM');
  }

  handleSubmit(ev) {
    // console.log("User clicked submit");
    // console.log(ev);
    console.log(this.props);
    this.setState({
      formState: FormState.isLoading,
    });
    const email = localStorage.getItem('email');
    const name = localStorage.getItem('name');

    this.props.stripe
      .createToken({ email, name })
      .then(({ token }) => {
        console.log('Received Stripe token:', token);
        analytics.logEvent(analytics.EventNames.SUBMITTED_PAYMENT_FORM, {
          plan: this.props.plan,
        });
        return firebase
          .firestore()
          .collection('signups')
          .doc(email)
          .update({ stripe: token });
      })
      .then(result => {
        this.setState({
          formState: FormState.isSubmitted,
        });
        return result;
      })
      .catch(error => {
        console.log('ERROR: ', error);
        analytics.logEvent('SUBMIT PAYMENT FORM ERROR', {
          plan: this.props.plan,
        });
      });
  }

  render() {
    if (this.state.formState === FormState.isSubmitted) {
      return (
        <Segment color="pink">
          <WaitlistComponent />
        </Segment>
      );
    }
    return (
      <div>
        <List size="medium">
          <List.Item>
            <List.Header>Start 7 Day Free Trial</List.Header>
          </List.Item>
          <List.Item>
            <Icon name="check circle" color="green" />
            Your card will not be charged until the end of your 7-day trial
          </List.Item>
          <List.Item>
            <Icon name="check circle" color="green" />
            Increase Your Real Followers Daily
          </List.Item>
          <List.Item>
            <Icon name="check circle" color="green" />
            Cancel your trial anytime with no cancellation fees
          </List.Item>
        </List>
        <Segment color="pink" padded="very">
          <div className="checkout">
            <br />
            <CardElement />
            <br />
            <Button
              loading={this.state.formState === FormState.isLoading}
              size="big"
              fluid
              primary
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Segment>
      </div>
    );
  }
}

export default injectStripe(PaymentFormComponent);
