import React from "react";
import { Motion, spring } from "react-motion";

class ScrollSink extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props.scrollTop) {
      document.scrollingElement.scrollTop = this.props.scrollTop;
    }
  }

  render() {
    return null;
  }
}

const renderScrollSink = currentStyles => {
  return <ScrollSink scrollTop={currentStyles.scrollTop} />;
};

const MotionScroll = ({ scrollTop }) => (
  <Motion style={{ scrollTop: spring(scrollTop) }}>{renderScrollSink}</Motion>
);

export default MotionScroll;
