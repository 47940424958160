import React from "react";
import { Container } from "semantic-ui-react";
import FrameContainer from "./FrameContainer";

const PrivacyComponent = () => (
  <FrameContainer>
    <Container style={{ padding: "5em 0 12em" }} fluid>
      <iframe
        width="100%"
        style={{ height: "95vh" }}
        src="https://app.termly.io/document/privacy-policy/d36e14e2-92e8-408a-bd4b-89bcbdfc9665"
        frameBorder="0"
        allowFullScreen
        title="Privacy Policy"
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </Container>
  </FrameContainer>
);

export default PrivacyComponent;
