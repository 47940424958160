import React from "react";
import {
  Container,
  Header,
  Grid,
  Icon,
  List,
  Segment
} from "semantic-ui-react";

const FooterComponent = props => (
  <Container fluid>
    <Segment inverted vertical style={{ padding: "5em 0em" }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Menu" />
              <List link inverted>
                <List.Item as="a" onClick={() => props.scrollToSection("home")}>
                  Home
                </List.Item>
                <List.Item
                  as="a"
                  onClick={() => props.scrollToSection("benefits")}
                >
                  Benefits
                </List.Item>
                <List.Item
                  as="a"
                  onClick={() => props.scrollToSection("features")}
                >
                  Features
                </List.Item>
                <List.Item
                  as="a"
                  onClick={() => props.scrollToSection("pricing")}
                >
                  Pricing
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Usage" />
              <List link inverted>
                <List.Item
                  as="a"
                  href="https://mogulkit.co/privacy"
                  target="_blank"
                >
                  Privacy Policy
                </List.Item>
                <List.Item
                  as="a"
                  href="https://mogulkit.co/terms"
                  target="_blank"
                >
                  Terms and Conditions
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as="h4" inverted>
                <Icon color="pink" name="chain" />
                MogulKit
              </Header>
              <p>Gain a massive Instagram following organically</p>
              <List link inverted>
                <List.Item
                  as="a"
                  href="https://www.instagram.com/mogulkit"
                  target="_blank"
                >
                  <Icon name="instagram" /> Instagram
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </Container>
);

export default FooterComponent;
