const plans = [
  {
    header: "Starter",
    iconName: "settings",
    cost: "$9/mo",
    features: ["2 scheduled posts per week", "Track 5 hashtags", "1 User"],
    link: "#starter",
    segmentProperties: {},
    labelColor: {},
    headerColor: "black",
    primaryButton: false
  },
  {
    header: "Popular",
    iconName: "rocket",
    cost: "$29/mo",
    features: [
      "5 scheduled posts per week",
      "Track 20 hashtags",
      "2 Users",
      "Mentions Analytics"
    ],
    link: "#popular",
    segmentProperties: {
      color: "grey",
      inverted: true
    },
    labelColor: { color: "pink" },
    headerColor: "green",
    primaryButton: true
  },
  {
    header: "Business",
    iconName: "star",
    cost: "$59/mo",
    features: [
      "Unlimited scheduled posts per week",
      "Track unlimited hashtags",
      "Mentions Analytics",
      "Unlimited Users",
      "Business Insights and Analytics"
    ],
    link: "#business",
    segmentProperties: {},
    labelColor: {},
    headerColor: "black",
    primaryButton: false
  }
];

export const plansHash = {
  starter: plans[0],
  popular: plans[1],
  business: plans[2]
};

export default plans;
