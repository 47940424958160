import React from "react";
import { Header, Icon, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

const MenuComponent = props => (
  <Menu inverted secondary size="large">
    <Menu.Menu position="left" style={{ marginRight: "auto" }}>
      <Menu.Item as={Link} to="/">
        <Header as="h2" inverted>
          <Icon color="pink" name="chain" />
          MogulKit
        </Header>
      </Menu.Item>
    </Menu.Menu>
    {props.children}
  </Menu>
);

export default MenuComponent;
