import React from "react";
import { Container, Header, Icon } from "semantic-ui-react";
import { Elements, StripeProvider } from "react-stripe-elements";

import PaymentFormComponent from "./PaymentFormComponent";
import TopbarComponent from "./TopbarComponent";
import { plansHash } from "./plans";

const PaymentFormContainer = props => {
  // const apiKey = "pk_test_GOuEQNLId8Y8fJqZKjUmzxRN";
  const apiKey = "pk_live_Jn05SaTdDrhMRguQLTyEXRGC";
  const { location } = props || {};
  const { hash } = location;
  const planKey = hash.replace("#", "");
  const plan = plansHash[planKey];
  return (
    <div>
      <Container
        fluid
        style={{
          backgroundColor: "#ffffff",
          position: "fixed",
          top: "0",
          right: "0",
          left: "0",
          zIndex: "1001",
          paddingTop: "0.75em",
          paddingBottom: "0.75em"
        }}
        id="topBarComponent"
      >
        <Container>
          <TopbarComponent />
        </Container>
      </Container>
      <Container text style={{ marginTop: "6em" }}>
        <Header textAlign="center" icon>
          <Icon name={plan.iconName} />
          <Header.Content>
            {plan.header}
            <Header.Subheader>{plan.cost}</Header.Subheader>
          </Header.Content>
        </Header>

        <StripeProvider apiKey={apiKey}>
          <Elements>
            <PaymentFormComponent plan={plan} />
          </Elements>
        </StripeProvider>
      </Container>
    </div>
  );
};

export default PaymentFormContainer;
