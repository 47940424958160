import React from "react";
import {
  Button,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Segment
} from "semantic-ui-react";
import { Link } from "react-router-dom";

const PricingCardComponent = props => (
  <Grid.Column>
    <Segment basic {...props.segmentProperties}>
      <Label attached="top" {...props.labelColor}>
        <Header textAlign="center" as="h3">
          {props.header}
        </Header>
      </Label>
      <Header color={props.headerColor} textAlign="center" as="h2" icon>
        <br />
        <Icon name={props.iconName} />
        <br />
        <div>{props.cost}</div>
        <Header.Subheader style={{ minHeight: "116px", minWidth: "270px" }}>
          <List items={props.features} />
        </Header.Subheader>
      </Header>
      <Button
        primary={props.primaryButton}
        fluid
        style={{ margin: "2em 0 0" }}
        content="Get Started"
        as={Link}
        to={`/signup${props.link}`}
      />
    </Segment>
  </Grid.Column>
);

export default PricingCardComponent;
