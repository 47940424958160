import React from "react";
import { Dropdown, Grid, Header, Menu } from "semantic-ui-react";

const MenuComponent = props => (
  <Grid>
    <Grid.Column only="tablet mobile" verticalAlign="middle">
      <Menu.Menu position="right">
        <Dropdown item icon="sidebar">
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => props.handleClick("home")}
              active={props.activeSection === "home"}
            >
              Home
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.handleClick("benefits")}
              active={props.activeSection === "benefits"}
            >
              Benefits
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.handleClick("features")}
              active={props.activeSection === "features"}
            >
              Features
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.handleClick("pricing")}
              active={props.activeSection === "pricing"}
            >
              Pricing
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.handleClick("pricing")}
              active={props.activeSection === "pricing"}
            >
              <Header color="pink">Get Started</Header>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Grid.Column>
    <Grid.Column only="computer" verticalAlign="middle">
      <Menu.Menu position="right">
        <Menu.Item
          as="a"
          onClick={() => props.handleClick("home")}
          active={props.activeSection === "home"}
        >
          Home
        </Menu.Item>
        <Menu.Item
          onClick={() => props.handleClick("benefits")}
          as="a"
          active={props.activeSection === "benefits"}
        >
          Benefits
        </Menu.Item>
        <Menu.Item
          onClick={() => props.handleClick("features")}
          as="a"
          active={props.activeSection === "features"}
        >
          Features
        </Menu.Item>
        <Menu.Item
          onClick={() => props.handleClick("pricing")}
          as="a"
          active={props.activeSection === "pricing"}
        >
          Pricing
        </Menu.Item>
        <Menu.Item onClick={() => props.handleClick("pricing")} as="a">
          <Header size="small" color="pink">
            Get Started
          </Header>
        </Menu.Item>
      </Menu.Menu>
    </Grid.Column>
  </Grid>
);

export default MenuComponent;
