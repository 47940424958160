import React from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import PricingCardComponent from "./PricingCardComponent";

import plans from "./plans";

const PricingComponent = () => (
  <Container id="pricing">
    <Header
      as="h2"
      content="Pricing"
      // subheader="7 Day free trial"
      style={{
        fontSize: "3.0em",
        fontWeight: "normal",
        marginTop: "1em",
        marginBottom: "0em",
        paddingTop: "1em",
        paddingBottom: "0.7em"
      }}
      textAlign="center"
    />
    <Grid columns={3} stackable divided>
      {plans.map((plan, index) => (
        <PricingCardComponent key={`index-${index}`} {...plan} />
      ))}
    </Grid>
  </Container>
);

export default PricingComponent;
