import React from "react";
import { Container } from "semantic-ui-react";
import FrameContainer from "./FrameContainer";

const TermsComponent = () => (
  <FrameContainer>
    <Container style={{ padding: "5em 0 12em" }} fluid>
      <iframe
        width="100%"
        style={{ height: "95vh" }}
        src="https://app.termly.io/document/terms-of-use-for-saas/f453be7e-10ae-4155-845b-e4c58871c111"
        frameBorder="0"
        allowFullScreen
        title="Terms and Conditions"
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </Container>
  </FrameContainer>
);

export default TermsComponent;
