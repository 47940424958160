const EventNames = {
  SUBMITTED_EMAIL_FORM: "SUBMITTED_EMAIL_FORM",
  SUBMITTED_SIGNUP_FORM: "SUBMITTED_SIGNUP_FORM",
  SUBMITTED_PAYMENT_FORM: "SUBMITTED_PAYMENT_FORM",
  SELECTED_MENU_ITEM: "SELECTED_MENU_ITEM",
  SELECTED_PRICE_TIER: "SELECTED_PRICE_TIER",
  VIEWED_SECTION: "VIEWED_SECTION"
};

const logEvent = (eventName, properties) => {
  window.amplitude.getInstance().logEvent(eventName, properties);
  window.fbq("trackCustom", eventName, properties);
  window.ga("send", "event", "CUSTOM", eventName);
};

const logUserId = userId => window.amplitude.getInstance().setUserId(userId);

export { EventNames, logEvent, logUserId };

export default { EventNames, logEvent, logUserId };
